import { getTranlations } from "@helpers";

import { PageLayout, FeatureBlock, AppIcon } from "@components";

import "@styles/SelectionPage.scss";

export default function AdvancedIndex() {
  const tkeys = getTranlations();

  let xfeatures = tkeys.features.map((item) => (
    <FeatureBlock
      title={item.name}
      link={item.link}
      icon={<AppIcon name={item.icon} />}
    />
  ));

  return (
    <PageLayout
      title="Fonctionnalités avancées"
      defaultBodyClassName="features-root"
    >
      <div className="features">{xfeatures}</div>
    </PageLayout>
  );
}
